import axios from 'axios';

export const simulatefund = async (token, tradingMode, fund, startDate, endDate, ai, maxBudget, country = 'KOREA') => {
  try {
    const API_URL = `/api/${tradingMode.toLowerCase()}/trades/simulate`;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        'cache-control': 'no-store',
        pragma: 'no-store',
        expires: '0'
      },
      params: {
        userId: token._id,
        fund,
        startDate,
        endDate,
        ai,
        maxBudget,
        country
      }
    };

    const response = await axios.get(API_URL, config);

    return response.data;
  } catch (error) {
    // console.log(error);

    throw error;
  }
};
