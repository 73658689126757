import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';
import { ENV } from '../../lib/utils';

const initialState = {
  token: null,
  profile: null,
  credentials: null,
  preferences: null,
  myFunds: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

// Register new user
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  try {
    return await authService.register(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Logout user
export const logout = createAsyncThunk('auth/logout', async userId => {
  await authService.logout(userId);
});

// Forgot password
export const forgotPassword = createAsyncThunk('auth/forgotpassword', async (email, thunkAPI) => {
  try {
    return await authService.forgotPassword(email);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Reset password
export const resetPassword = createAsyncThunk('auth/resetpassword', async (data, thunkAPI) => {
  try {
    const password = data.password;
    const resetToken = data.resetToken;

    return await authService.resetPassword(password, resetToken);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Set user profile
export const setProfile = createAsyncThunk('auth/setprofile', async (profile, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token.token;
    const userId = thunkAPI.getState().auth.token._id;

    return await authService.setProfile(token, userId, profile);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Get user credentials
export const getCredentials = createAsyncThunk('auth/getcredentials', async (tradingMode, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token.token;
    const userId = thunkAPI.getState().auth.token._id;

    return await authService.getCredentials(token, userId, tradingMode);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Set user credentials
export const setCredentials = createAsyncThunk('auth/setcredentials', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token.token;
    const userId = thunkAPI.getState().auth.token._id;
    const credentials = data.credentials;
    const tradingMode = data.isSandbox ? ENV.SANDBOX : ENV.PRODUCTION;

    return await authService.setCredentials(token, userId, credentials, tradingMode);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Get user preferences
export const getPreferences = createAsyncThunk('auth/getpreferences', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token.token;
    const userId = thunkAPI.getState().auth.token._id;

    return await authService.getPreferences(token, userId);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Set user preferences
export const setPreferences = createAsyncThunk('auth/setpreferences', async (preferences, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token.token;
    const userId = thunkAPI.getState().auth.token._id;

    return await authService.setPreferences(token, userId, preferences);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Get my funds list
export const getMyFunds = createAsyncThunk('auth/getmyfunds', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token.token;
    const userId = thunkAPI.getState().auth.token._id;

    return await authService.getMyFunds(token, userId);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// Set my funds list
export const setMyFunds = createAsyncThunk('auth/setmyfunds', async (myfunds, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.token.token;
    const userId = thunkAPI.getState().auth.token._id;

    return await authService.setMyFunds(token, userId, myfunds);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, state => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = {
          _id: action.payload._id,
          token: action.payload.token,
          email: action.payload.email,
          name: action.payload.name
        };
        state.userId = action.payload._id;
        state.preferences = action.payload.preferences;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.token = null;
      })
      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = {
          _id: action.payload._id,
          token: action.payload.token,
          email: action.payload.email,
          name: action.payload.name,
          egleo: action.payload.egleo
        };
        state.userId = action.payload._id;
        state.credentials = action.payload.kis;
        state.preferences = action.payload.preferences;
        state.myFunds = action.payload.myFunds;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.token = null;
      })
      .addCase(forgotPassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resetPassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(logout.fulfilled, state => {
        state.token = null;
        state.userId = null;
        state.credentials = null;
        state.preferences = null;
        state.myFunds = null;
      })
      .addCase(setProfile.pending, state => {
        state.isLoading = true;
      })
      .addCase(setProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token.name = action.payload;
      })
      .addCase(setProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCredentials.pending, state => {
        state.isLoading = true;
      })
      .addCase(getCredentials.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.credentials = action.payload;
      })
      .addCase(getCredentials.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(setCredentials.pending, state => {
        state.isLoading = true;
      })
      .addCase(setCredentials.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.credentials = action.payload;
      })
      .addCase(setCredentials.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPreferences.pending, state => {
        state.isLoading = true;
      })
      .addCase(getPreferences.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.preferences = action.payload;
      })
      .addCase(getPreferences.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(setPreferences.pending, state => {
        state.isLoading = true;
      })
      .addCase(setPreferences.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.preferences = action.payload;
      })
      .addCase(setPreferences.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMyFunds.pending, state => {
        state.isLoading = true;
      })
      .addCase(getMyFunds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.myFunds = action.payload;
      })
      .addCase(getMyFunds.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        // state.myFunds = null;
        state.message = action.payload;
      })
      .addCase(setMyFunds.pending, state => {
        state.isLoading = true;
      })
      .addCase(setMyFunds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.myFunds = action.payload;
      })
      .addCase(setMyFunds.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { resetAuth } = authSlice.actions;
export default authSlice.reducer;
