import moment from 'moment-timezone';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL, tooltipStyle, COUNTRY } from '../../lib/utils';
import { toast } from 'react-toastify';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LineHighlightPlot, LinePlot } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import LoadingButton from '@mui/lab/LoadingButton';
import AssistantIcon from '@mui/icons-material/Assistant';
import { simulatefund } from '../../api/simulatefund';

const Simulation = () => {
  const navigate = useNavigate();

  const { token, preferences } = useSelector(state => state.auth);
  const tradingMode = preferences.tradingMode;

  const today = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'd').format('YYYY-MM-DD');
  let fromDate = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'M').format('YYYY-MM-DD');

  const [result, setResult] = useState(null);
  const [fund, setFund] = useState('PRIMARY');
  const [country, setCountry] = useState('KOREA');
  const [period, setPeriod] = useState('1M');
  const [ai, setAI] = useState('AI0');
  const [maxBudget, setMaxBudget] = useState('0.3');
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [plotData, setPlotData] = useState([]);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const isIndexFund = fund === 'INDEX';

  const tableStyle = {
    fontSize: '14px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    lineHeight: '52px',
    paddingLeft: '1rem'
  };

  const series = [
    {
      type: 'bar',
      yAxisId: 'balance',
      label: 'Account Balance',
      color: 'lightgray',
      data: plotData.map(day => day.accountBalance),
      highlightScope: { highlight: 'item' }
    },
    {
      type: 'line',
      yAxisId: 'profit',
      label: 'Profit',
      color: '#00AEEF',
      data: plotData.map(day => day.profit),
      highlightScope: { highlight: 'item' }
    },
    {
      type: 'line',
      yAxisId: 'profit',
      color: 'red',
      label: 'Profit Ratio',
      data: plotData.map(day => day.profitRatio),
      valueFormatter: (v, { dataIndex }) => {
        if (!v) return;

        const { profitRatio } = plotData[dataIndex];
        return profitRatio.toFixed(2) + '%';
      }
    }
  ];

  const displayResult = async () => {
    setResult(null);

    setIsLoading(true);

    try {
      const result = await simulatefund(token, tradingMode, fund, startDate, endDate, ai, maxBudget, country);

      setResult({
        fund,
        startDate,
        endDate,
        ai:
          ai === 'AI1'
            ? 'AI 1'
            : ai === 'AI2'
            ? 'AI 2'
            : ai === 'AI3'
            ? 'AI 3'
            : ai === 'AI4'
            ? 'AI 4'
            : ai === 'AI5'
            ? 'AI 5'
            : 'AI 0',
        accountBalance: result.accountBalance,
        cashBalance: result.cashBalance,
        profit: result.profit,
        profitRatio: result.profitRatio,
        performanceSummary: result.performanceSummary
      });

      result.performanceSummary.length > 0 && setPlotData(result.performanceSummary);
    } catch (error) {
      toast.error(error.response.data.message);
      // return;
    }

    setIsLoading(false);
  };

  const Item = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.body2
    // padding: theme.spacing(1),
  }));

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }

    const month = period === '1M' || period === 'CUSTOM' ? 1 : period === '3M' ? 3 : 6;
    const fromDate = moment.tz(new Date(), 'Asia/Seoul').subtract(month, 'M').format('YYYY-MM-DD');

    setStartDate(fromDate);
  }, [token, navigate, period]);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={isMobile ? 4 : 6}
        fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
      >
        <AssistantIcon fontSize={isMobile ? 'medium' : 'large'} /> Fund Simulation
      </Typography>

      <Box
        // display={'grid'}
        // gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
        maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', mt: 10, typography: 'body1' }}
      >
        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={2}
        >
          <Typography variant='body2' textAlign={'end'}>
            Trade Market
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={country}
            exclusive
            onChange={(e, country) => {
              if (country !== null) setCountry(country);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='KOREA'>KOREA</ToggleButton>
            <ToggleButton value='USA'>USA</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Fund
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={fund}
            exclusive
            onChange={(e, fund) => {
              if (fund !== null) setFund(fund);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='PRIMARY'>PRIMARY</ToggleButton>
            <ToggleButton value='SECONDARY'>SECONDARY</ToggleButton>
            <ToggleButton value='INDEX'>INDEX</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Period
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={period}
            exclusive
            onChange={(e, period) => {
              if (period !== null) setPeriod(period);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='1M'>1 mon</ToggleButton>
            <ToggleButton value='3M'>3 mon</ToggleButton>
            <ToggleButton value='6M'>6 mon</ToggleButton>
            <ToggleButton value='CUSTOM'>Custom</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {period === 'CUSTOM' && (
          <Stack
            display={'grid'}
            gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
            alignItems={'center'}
            gap={0}
            px={{ xs: 0, sm: 18 }}
            mt={3}
          >
            <Typography variant='body2' textAlign={'end'}>
              {''}
            </Typography>
            <Box display={'flex'} alignItems={'center'}>
              <DatePicker
                label='From'
                sx={{ width: '180px' }}
                minDate={moment('2024-01-01')}
                maxDate={moment(today)}
                value={moment(startDate)}
                onChange={newValue => setStartDate(moment(newValue).format('YYYY-MM-DD'))}
              />
              <Typography px={2}>-</Typography>
              <DatePicker
                label='To'
                sx={{ width: '180px' }}
                minDate={moment(startDate)}
                maxDate={moment(today)}
                value={moment(endDate)}
                onChange={newValue => setEndDate(moment(newValue).format('YYYY-MM-DD'))}
              />
            </Box>
          </Stack>
        )}

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Algorithm
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={ai}
            exclusive
            onChange={(e, ai) => {
              if (ai !== null) setAI(ai);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='AI0'>AI 0</ToggleButton>
            <ToggleButton value='AI1'>AI 1</ToggleButton>
            <ToggleButton value='AI2'>AI 2</ToggleButton>
            <ToggleButton value='AI3'>AI 3</ToggleButton>
            <ToggleButton value='AI4'>AI 4</ToggleButton>
            <ToggleButton value='AI5'>AI 5</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Max Budget
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={isIndexFund ? '1.0' : maxBudget}
            exclusive
            onChange={(e, maxBudget) => {
              if (maxBudget !== null) setMaxBudget(maxBudget);
            }}
            sx={{ width: { xs: '240px', sm: '280px' } }}
          >
            <ToggleButton value='0.3' disabled={isIndexFund}>
              30%
            </ToggleButton>
            <ToggleButton value='0.5' disabled={isIndexFund}>
              50%
            </ToggleButton>
            <ToggleButton value='1.0' disabled={isIndexFund}>
              100%
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} gap={4} mt={4}>
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            startIcon={<AssistantIcon />}
            variant='contained'
            sx={{ width: '150px' }}
            onClick={() => displayResult()}
          >
            Simulate
          </LoadingButton>
        </Stack>

        <Box
          display={'flex'}
          justifyContent={'center'}
          gap={4}
          maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
          sx={{ width: '100%', margin: 'auto', mt: 4, typography: 'body1' }}
        >
          {result && (
            <Stack spacing={4} sx={{ width: '100%', margin: { xs: '2rem auto', md: '4rem auto' } }}>
              {/* simulation result summary */}
              <Box
                display={'flex'}
                gap={2}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography variant='h6'>
                  Simulation Result - <span style={{ color: '#00AEEF' }}>{result.fund}</span> fund
                </Typography>
                <Typography variant='body1' color={'primary'}>
                  Algorithm: {result.ai}
                </Typography>
              </Box>

              <Box
                display={'grid'}
                gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
                border={'1px solid rgba(255, 255, 255, 0.2)'}
                borderRadius={'6px'}
              >
                <Item style={tableStyle} backgroundColor='#001021'>
                  Start Date
                </Item>
                <Item style={tableStyle}>{result.startDate}</Item>
                <Item style={tableStyle} backgroundColor='#001021'>
                  End Date
                </Item>
                <Item style={tableStyle}>{result.endDate}</Item>
                <Item style={tableStyle} backgroundColor='#001021'>
                  Account Balance
                </Item>
                <Item style={tableStyle}>
                  {country === COUNTRY.KOREA
                    ? Math.abs(result.accountBalance).toLocaleString('en-us')
                    : `$${Math.abs(result.accountBalance).toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`}
                </Item>
                <Item style={tableStyle} backgroundColor='#001021'>
                  Cash Balance
                </Item>
                <Item style={tableStyle}>
                  {country === COUNTRY.KOREA
                    ? Math.abs(result.cashBalance).toLocaleString('en-us')
                    : `$${Math.abs(result.cashBalance).toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`}
                </Item>
                <Item style={tableStyle} backgroundColor='#001021'>
                  Profit
                </Item>
                <Item
                  style={tableStyle}
                  color={
                    result.profit && result.profit > 0
                      ? TICKCOLOR_UP
                      : result.profit && result.profit < 0
                      ? TICKCOLOR_DOWN
                      : TICKCOLOR_NEUTRAL
                  }
                >
                  {result.profit > 0 ? <ArrowDropUpIcon /> : result.profit < 0 ? <ArrowDropDownIcon /> : ''}
                  {country === COUNTRY.KOREA
                    ? Math.abs(result.profit).toLocaleString('en-us')
                    : `$${Math.abs(result.profit).toFixed(2).toLocaleString('en-us')}`}
                </Item>
                <Item style={tableStyle} backgroundColor='#001021'>
                  Profit %
                </Item>
                <Item
                  style={tableStyle}
                  color={
                    result.profitRatio && result.profitRatio > 0
                      ? TICKCOLOR_UP
                      : result.profitRatio && result.profitRatio < 0
                      ? TICKCOLOR_DOWN
                      : TICKCOLOR_NEUTRAL
                  }
                >
                  {result.profit > 0 ? <ArrowDropUpIcon /> : result.profit < 0 ? <ArrowDropDownIcon /> : ''}
                  {Math.abs(result.profitRatio).toFixed(2).toLocaleString('en-us') + '%'}
                </Item>
              </Box>

              {/* simulation result chart */}
              {plotData.length > 0 && (
                <Box display={'flex'} gap={2} pt={4} flexDirection={'column'}>
                  <Typography variant='h6'>Performance Chart</Typography>

                  <ResponsiveChartContainer
                    series={series}
                    height={450}
                    margin={{ top: 16 }}
                    xAxis={[
                      {
                        id: 'date',
                        data: plotData.map(day => new Date(day.date)),
                        scaleType: 'band',
                        valueFormatter: value => value.toLocaleDateString()
                      }
                    ]}
                    yAxis={[
                      {
                        id: 'profit',
                        scaleType: 'linear',
                        // valueFormatter: value => `${(value / 1000_000).toLocaleString()}M`
                        valueFormatter: value => {
                          if (country === COUNTRY.KOREA) {
                            return `${(value / 1_000_000).toLocaleString()}M`;
                          } else {
                            return `$${(value / 1000).toLocaleString()}K`;
                          }
                        }
                      },
                      {
                        id: 'balance',
                        scaleType: 'linear',
                        // valueFormatter: value => `${(value / 1000_000).toLocaleString()}M`
                        valueFormatter: value => {
                          if (country === COUNTRY.KOREA) {
                            return `${(value / 1_000_000).toLocaleString()}M`;
                          } else {
                            return `$${(value / 1000).toLocaleString()}K`;
                          }
                        }
                      }
                    ]}
                  >
                    <ChartsAxisHighlight x='line' />
                    <LinePlot />
                    <LineHighlightPlot />
                    <ChartsXAxis
                      label='Date'
                      position='bottom'
                      axisId='date'
                      tickInterval={(value, index) => {
                        return index % 3 === 0;
                      }}
                      tickLabelStyle={{ fontSize: 12 }}
                    />
                    <ChartsYAxis
                      label={country === COUNTRY.KOREA ? 'Profit (KRW)' : 'Profit (USD)'}
                      position='left'
                      axisId='profit'
                      tickLabelStyle={{ fontSize: 10 }}
                      sx={{
                        [`& .${axisClasses.label}`]: {
                          transform: 'translateX(-12px)'
                        }
                      }}
                    />
                    <ChartsYAxis
                      label={country === COUNTRY.KOREA ? 'Account Balance (KRW)' : 'Account Balance (USD)'}
                      position='right'
                      axisId='balance'
                      tickLabelStyle={{ fontSize: 10 }}
                      sx={{
                        [`& .${axisClasses.label}`]: {
                          transform: 'translateX(12px)'
                        }
                      }}
                    />
                    <ChartsTooltip />
                  </ResponsiveChartContainer>
                </Box>
              )}

              {/* simulation result details with daily data */}
              <Box display={'flex'} gap={2} pt={4} flexDirection={{ xs: 'column', md: 'row' }}>
                <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
                  <Typography variant='h6'>Daily Performance</Typography>
                </Box>
              </Box>

              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={1}
                mt={0}
                sx={{ overflowWrap: 'anywhere' }}
              >
                {result.performanceSummary.length === 0 ? (
                  <Typography>No trade transactions during the selected period.</Typography>
                ) : (
                  result.performanceSummary.map(dailyResult => (
                    <Grid container spacing={1} key={dailyResult.date}>
                      <Grid item xs={3}>
                        <Item color={'primary'}>Date: {dailyResult.date}</Item>
                      </Grid>
                      <Grid item xs={3}>
                        <Item>Account Balance</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          {country === COUNTRY.KOREA
                            ? Math.abs(dailyResult.accountBalance).toLocaleString('en-us')
                            : `$${Math.abs(dailyResult.accountBalance).toLocaleString('en-us', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}`}
                        </Item>
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Item>Cash Balance</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          {country === COUNTRY.KOREA
                            ? Math.abs(dailyResult.cashBalance).toLocaleString('en-us')
                            : `$${Math.abs(dailyResult.cashBalance).toLocaleString('en-us', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}`}
                        </Item>
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Item>Asset Balance</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          {country === COUNTRY.KOREA
                            ? Math.abs(dailyResult.assetBalance).toLocaleString('en-us')
                            : `$${Math.abs(dailyResult.assetBalance).toLocaleString('en-us', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}`}
                        </Item>
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Item>Profit</Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item
                          color={
                            dailyResult.profit && dailyResult.profit > 0
                              ? TICKCOLOR_UP
                              : dailyResult.profit && dailyResult.profit < 0
                              ? TICKCOLOR_DOWN
                              : TICKCOLOR_NEUTRAL
                          }
                        >
                          {dailyResult.profit > 0 ? (
                            <ArrowDropUpIcon />
                          ) : dailyResult.profit < 0 ? (
                            <ArrowDropDownIcon />
                          ) : (
                            ''
                          )}
                          {country === COUNTRY.KOREA
                            ? Math.abs(dailyResult.profit).toLocaleString('en-us')
                            : `$${Math.abs(dailyResult.profit).toFixed(2).toLocaleString('en-us')}`}
                        </Item>
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}>
                        <Item>Profit %</Item>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        color={
                          dailyResult.profitRatio > 0
                            ? TICKCOLOR_UP
                            : dailyResult.profitRatio < 0
                            ? TICKCOLOR_DOWN
                            : TICKCOLOR_NEUTRAL
                        }
                      >
                        <Item>
                          {dailyResult.profitRatio > 0 ? (
                            <ArrowDropUpIcon />
                          ) : dailyResult.profitRatio < 0 ? (
                            <ArrowDropDownIcon />
                          ) : (
                            ''
                          )}
                          {Math.abs(dailyResult.profitRatio).toFixed(2).toLocaleString('en-us') + '%'}
                        </Item>
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={9}>
                        <Item color={'primary'}>Portfolio</Item>
                      </Grid>
                      {dailyResult.portfolio.length === 0 ? (
                        <>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={9}>
                            <Item>No assets</Item>
                          </Grid>
                        </>
                      ) : (
                        dailyResult.portfolio.map(asset => (
                          <Grid container spacing={1} key={asset.ticker} pl={'8px'} pt={'8px'}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                              <Divider></Divider>
                            </Grid>

                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                disableFocusListener
                                arrow
                                title={`${asset.name} (${asset.ticker})`}
                                PopperProps={{ sx: tooltipStyle }}
                              >
                                <Link
                                  color='primary.light'
                                  underline='none'
                                  href={
                                    country === COUNTRY.KOREA
                                      ? `https://finance.naver.com/item/main.nhn?code=${asset.ticker}`
                                      : `https://finance.yahoo.com/quote/${asset.ticker}?p=${asset.ticker}`
                                  }
                                  target='_blank'
                                  rel='noreferrer'
                                >
                                  <Item
                                    color={'#90caf9'}
                                    display={'inline-block'}
                                    overflow={'hidden'}
                                    whiteSpace={'nowrap'}
                                    textOverflow={'ellipsis'}
                                    maxWidth={'220px'}
                                  >
                                    {asset.name}
                                  </Item>
                                </Link>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Item>Bought Date</Item>
                            </Grid>
                            <Grid item xs={3}>
                              <Item>{asset.buyAt}</Item>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              <Item>Bought Price</Item>
                            </Grid>
                            <Grid item xs={3}>
                              <Item>
                                {country === COUNTRY.KOREA
                                  ? Math.abs(asset.buyPrice).toLocaleString('en-us')
                                  : `$${Math.abs(asset.buyPrice).toFixed(2).toLocaleString('en-us')}`}
                              </Item>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              <Item>Quantity</Item>
                            </Grid>
                            <Grid item xs={3}>
                              <Item>{asset.quantity?.toLocaleString('en-us')}</Item>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              <Item>Profit</Item>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              color={
                                asset.profit > 0 ? TICKCOLOR_UP : asset.profit < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL
                              }
                            >
                              <Item>
                                {asset.profit > 0 ? <ArrowDropUpIcon /> : asset.profit < 0 ? <ArrowDropDownIcon /> : ''}
                                {country === COUNTRY.KOREA
                                  ? Math.abs(asset.profit).toLocaleString('en-us')
                                  : `$${Math.abs(asset.profit).toFixed(2).toLocaleString('en-us')}`}
                              </Item>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              <Item>Profit %</Item>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              color={
                                asset.profitRatio > 0
                                  ? TICKCOLOR_UP
                                  : asset.profitRatio < 0
                                  ? TICKCOLOR_DOWN
                                  : TICKCOLOR_NEUTRAL
                              }
                            >
                              <Item>
                                {asset.profitRatio > 0 ? (
                                  <ArrowDropUpIcon />
                                ) : asset.profitRatio < 0 ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  ''
                                )}
                                {Math.abs(asset.profitRatio).toFixed(2).toLocaleString('en-us') + '%'}
                              </Item>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              <Item>Open</Item>
                            </Grid>
                            <Grid item xs={3}>
                              <Item>
                                {country === COUNTRY.KOREA
                                  ? Math.abs(asset.open).toLocaleString('en-us')
                                  : `$${Math.abs(asset.open).toFixed(2).toLocaleString('en-us')}`}
                              </Item>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                              <Item>Close</Item>
                            </Grid>
                            <Grid item xs={3}>
                              <Item>
                                {country === COUNTRY.KOREA
                                  ? Math.abs(asset.close).toLocaleString('en-us')
                                  : `$${Math.abs(asset.close).toFixed(2).toLocaleString('en-us')}`}
                              </Item>
                            </Grid>
                          </Grid>
                        ))
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ margin: '8px 0' }}></Divider>
                      </Grid>
                    </Grid>
                  ))
                )}
              </Box>
            </Stack>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Simulation;
