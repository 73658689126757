import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCredentials, setPreferences } from '../../features/auth/authSlice';
import { isEmptyObject, tooltipStyle, ENV } from '../../lib/utils';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import AddchartIcon from '@mui/icons-material/Addchart';
import AssistantIcon from '@mui/icons-material/Assistant';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import KeyIcon from '@mui/icons-material/Key';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ShieldIcon from '@mui/icons-material/Shield';
import GradingIcon from '@mui/icons-material/Grading';
const Sidebar = ({ setIsClosing, setMobileOpen, mobileOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, credentials, preferences } = useSelector(state => state.auth);
  const [env, setEnv] = useState(preferences?.tradingMode ? preferences.tradingMode : 'SANDBOX');
  const isValidCredentials = isEmptyObject(credentials) ? false : true;
  const isAdminUser =
    token?.email === 'gatorskim@gmail.com' ||
    token?.email === 'ciseo0929@gmail.com' ||
    token?.email === 'okju0307@nate.com' ||
    token?.email === 'yesang7@naver.com' ||
    token?.email === 'xips@naver.com';
  const isSuperAdmin = token?.email === 'gatorskim@gmail.com' || token?.email === 'ciseo0929@gmail.com';

  const isMobile = useMediaQuery('(max-width: 600px)');
  const drawerWidth = isMobile ? '100%' : 220;

  const handleClick = e => {
    if (![ENV.SANDBOX, ENV.PRODUCTION].includes(e.target.value)) return;

    setEnv(e.target.value);

    const preferenceData = {
      tradingMode: e.target.value
    };

    const newPreferences = { ...preferences, ...preferenceData };

    dispatch(setPreferences(newPreferences));
    dispatch(getCredentials(e.target.value));
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const drawer = (
    <>
      <Toolbar sx={{ minHeight: { xs: '66px', md: '68px' } }} />
      <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} flexGrow={'inherit'}>
        {token ? (
          <>
            <List sx={{ width: '100%' }} component='nav'>
              <ListItemButton
                onClick={() => {
                  navigate('/dashboard');
                  handleDrawerClose();
                }}
              >
                <ListItemIcon sx={{ minWidth: '48px' }}>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Dashboard'
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
              </ListItemButton>
              <Divider />
              <ListItem>
                <ListItemText
                  primary='Trading'
                  primaryTypographyProps={{
                    fontSize: 16,
                    color: 'primary'
                  }}
                />
              </ListItem>
              <Tooltip
                disableFocusListener
                arrow
                title={isValidCredentials ? null : 'Valid credentials required to enable these menus'}
                PopperProps={{ sx: tooltipStyle }}
                placement='right'
              >
                <span>
                  <ListItemButton
                    onClick={() => {
                      navigate('/search');
                      handleDrawerClose();
                    }}
                    disabled={!isValidCredentials ? true : false}
                  >
                    <ListItemIcon sx={{ minWidth: '48px' }}>
                      <SearchIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Search Asset'
                      primaryTypographyProps={{
                        fontSize: 14
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate('/myfund');
                      handleDrawerClose();
                    }}
                    disabled={!isValidCredentials ? true : false}
                  >
                    <ListItemIcon sx={{ minWidth: '48px' }}>
                      <AddchartIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='My Fund'
                      primaryTypographyProps={{
                        fontSize: 14
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate('/simulation');
                      handleDrawerClose();
                    }}
                    sx={{ display: isSuperAdmin ? 'flex' : 'none' }}
                    disabled={!isValidCredentials ? true : false}
                  >
                    <ListItemIcon sx={{ minWidth: '48px' }}>
                      <AssistantIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Simulation'
                      primaryTypographyProps={{
                        fontSize: 14
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate('/portfolio');
                      handleDrawerClose();
                    }}
                    disabled={!isValidCredentials ? true : false}
                  >
                    <ListItemIcon sx={{ minWidth: '48px' }}>
                      <InsightsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Portfolio'
                      primaryTypographyProps={{
                        fontSize: 14
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      navigate('/log');
                      handleDrawerClose();
                    }}
                    disabled={!isValidCredentials ? true : false}
                  >
                    <ListItemIcon sx={{ minWidth: '48px' }}>
                      <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Trade Log'
                      primaryTypographyProps={{
                        fontSize: 14
                      }}
                    />
                  </ListItemButton>
                </span>
              </Tooltip>
              <Divider />
              <ListItem>
                <ListItemText
                  primary='My Account'
                  primaryTypographyProps={{
                    fontSize: 16,
                    color: 'primary'
                  }}
                />
              </ListItem>
              <ListItemButton
                onClick={() => {
                  navigate('/profile');
                  handleDrawerClose();
                }}
              >
                <ListItemIcon sx={{ minWidth: '48px' }}>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Profile'
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate('/credentials');
                  handleDrawerClose();
                }}
              >
                <ListItemIcon sx={{ minWidth: '48px' }}>
                  <KeyIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Credentials'
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
              </ListItemButton>
              <Divider />
              <ListItem>
                <ListItemText
                  primary='Settings'
                  primaryTypographyProps={{
                    fontSize: 16,
                    color: 'primary'
                  }}
                />
              </ListItem>
              <ListItemButton
                onClick={() => {
                  navigate('/settings');
                  handleDrawerClose();
                }}
              >
                <ListItemIcon sx={{ minWidth: '48px' }}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Trade Settings'
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
              </ListItemButton>
            </List>

            {isAdminUser ? (
              <Box>
                <Divider />
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'column'}
                  flexGrow={'inherit'}
                  p={2}
                >
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} pb={2}>
                    <ListItemIcon sx={{ minWidth: '48px' }}>
                      <CurrencyExchangeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary='Trading Mode'
                      primaryTypographyProps={{
                        fontSize: 14
                      }}
                    />
                  </Box>
                  <ToggleButtonGroup
                    size='small'
                    color='primary'
                    width='100%'
                    p={2}
                    value={env}
                    exclusive
                    aria-label='Trading Mode'
                  >
                    <ToggleButton key={ENV.SANDBOX} value={ENV.SANDBOX} onClick={handleClick} sx={{ fontSize: '12px' }}>
                      Sandbox
                    </ToggleButton>
                    <ToggleButton
                      key={ENV.PRODUCTION}
                      value={ENV.PRODUCTION}
                      disabled={!isAdminUser}
                      onClick={handleClick}
                      sx={{ fontSize: '12px' }}
                    >
                      Live
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Box>
            ) : null}
          </>
        ) : (
          <>
            <List sx={{ width: '100%' }} component='nav'>
              <ListItemButton
                onClick={() => {
                  navigate('/');
                  handleDrawerClose();
                }}
              >
                <ListItemIcon sx={{ minWidth: '48px' }}>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Home'
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                onClick={() => {
                  navigate('/login');
                  handleDrawerClose();
                }}
              >
                <ListItemIcon sx={{ minWidth: '48px' }}>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Log In'
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate('/register');
                  handleDrawerClose();
                }}
              >
                <ListItemIcon sx={{ minWidth: '48px' }}>
                  <HowToRegIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Sign Up'
                  primaryTypographyProps={{
                    fontSize: 14
                  }}
                />
              </ListItemButton>
            </List>

            <Box>
              <Divider />
              <List sx={{ width: '100%' }} component='nav'>
                <ListItemButton
                  onClick={() => {
                    navigate('/legal/privacy');
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '48px' }}>
                    <ShieldIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary='Privacy Policy'
                    primaryTypographyProps={{
                      fontSize: 14
                    }}
                  />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate('/legal/tos');
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '48px' }}>
                    <GradingIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary='Terms of Service'
                    primaryTypographyProps={{
                      fontSize: 14
                    }}
                  />
                </ListItemButton>
              </List>
            </Box>
          </>
        )}
      </Box>
    </>
  );

  return (
    <>
      {token ? (
        <>
          <Drawer
            variant='permanent'
            anchor='left'
            sx={{
              display: { xs: 'none', md: 'block' },
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                background: 'linear-gradient(rgba(8, 18, 52) 0%, rgba(2, 11, 36) 100%)'
                // backgroundColor: 'background.sidebar'
              }
            }}
            open
          >
            {drawer}
          </Drawer>
          <Drawer
            variant='temporary'
            anchor='left'
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                background: 'linear-gradient(rgba(8, 18, 52) 0%, rgba(2, 11, 36) 100%)'
                // backgroundColor: 'background.sidebar'
              }
            }}
          >
            {drawer}
          </Drawer>
        </>
      ) : (
        <Drawer
          variant='temporary'
          anchor='right'
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              background: 'linear-gradient(rgba(8, 18, 52) 0%, rgba(2, 11, 36) 100%)'
              // backgroundColor: 'background.sidebar'
            }
          }}
        >
          {drawer}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
